<!-- 1440 安全  -->
<template>
	<section class="header_view">
		<div class="header">
			<div class="headerBox">
				<div class="boxLeft">
					<ul>
						<li>
							<img :src="require('@/assets/image/icon/logo2.png')" alt="" />
						</li>
						<li v-for="(item, index) in nav" :key="index" :class="select == item.id ? 'select' : ''"
							@click="but(item)">
							{{ item.name }}
						</li>
					</ul>
				</div>
				<!-- <div class="boxRight"> -->
					<!-- 在線報名 -->
					<!-- 報名尚未開始 -->
					<!-- <span @click="aheftBut()">在線報名</span> -->
					<!-- <span>複賽結束</span> -->
				<!-- </div> -->
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: "views",
		data() {
			return {
				nav: [{
						id: 0,
						name: "活動首頁",
						path: "/",
						href: null,
						type:1,//1是本地跳轉   2,pdf預覽   3,外鏈
					},
					// {
					// 	id:1,
					// 	name:'比賽指引',
					// 	path:'/',
					//   href:null,
					// },
					{
						id: 2,
						name: "比賽誦材",
						path: "/material",
						href: null,
						type:1,//1是本地跳轉   2,pdf預覽   3,外鏈
					},
					{
						id: 3,
						name: "成績公示",
						path: "/achievement",
						href: null,
						type:1,//1是本地跳轉   2,pdf預覽   3,外鏈
					},
					{
						id: 4,
						name: "比賽章程",
						path: "/",
						href: "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-09/ea2953b22d68bba15c95dc5f6ebfb24d.pdf",
						type:3,//1是本地跳轉   2,pdf預覽   3,外鏈
					},
					{
						id: 5,
						name: "比賽APP下載",
						path: "/",
						href: "https://app.chineserd.hk",
						type:3,//1是本地跳轉   2,pdf預覽   3,外鏈
					},
				],
				select: 0,
			};
		},
		props: {
			select: {
				type: String,
				default: 0
			},

		},
		computed: {},
		components: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
			getQueryString: function(name) {
				return (
					decodeURIComponent(
					(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
						location.href
					) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			aheftBut(e){ 
				if(this.getQueryString('c') != null ||this.getQueryString('c') !=undefined){
					this.$router.push('/signup?c='+this.getQueryString('c'))
				}else{
					this.$router.push('/signup')
				}  
			},
			heftBut(e) {
				this.$router.push(e)
			},
			but(e) {
				if (e.id == this.select) {
					console.log('不生效')
					this.popupShow = false;
				} else {
					console.log(e)
					
					switch (e.type) {
						case 1:
						this.$router.push(e.path);
							break;
						case 2:
							let baseurl = 'http://chineserd.cn/h5/html/regulations/index.html?url='+ e.href
							window.open(baseurl, "_blank");
							break;
						case 3:
							window.open(e.href, "_blank");
							break; 
						default:
							break;
					} 
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.header_view {
		width: 100%;
		height: 80px;
		position: relative;

		.header {
			position: sticky;
			width: 100%;
			transition: background 0.3s, border 0.3s, border-radius 0.3s,
				box-shadow 0.3s;
			position: fixed;
			z-index: 99999;
			background: #FF891F;
			box-shadow: 0px 4px 20px 0px rgba(164, 79, 0, 0.15);

			.headerBox {
				max-width: 1246px;
				height: 80px;
				margin: auto;
				display: flex;
				justify-content: space-between;

				.boxLeft {
					height: 100%;

					ul {
						height: 100%;
						display: flex;

						li {
							width: 160px;
							height: 100%;
							font-size: 20px;
							font-family: PingFang SC-Semibold, PingFang SC;
							color: #FFFFFF;
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;

							img {
								width: 80px;
							}
						}

						.select {
							font-weight: bold;
							background: url("~@/assets/image/hodgepodge/tabBut.png") no-repeat;
							background-size: 100% 100%;
						}
					}
				}

				.boxRight {
					height: 100%;
					display: flex;
					align-items: center;
					color: #ffffff;
					// cursor: pointer;
					cursor: not-allowed;
					span {
						min-width: 136px;
						height: 48px;
						// background: url('~@/assets/image/icon/but.png') no-repeat;
						// background-size: 100% 100%;
						background:#D0D0D0;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 20px;
						font-family: PingFang SC-Semibold, PingFang SC;
						font-weight: 600;
						color: #FFFFFF;
						line-height:15px;
						// box-shadow: 0px 4px 9px 0px rgba(244, 86, 5, 0.4);
						box-shadow: 0px 4px 9px 0px rgba(208, 208, 208, 0.4);
						border-radius: 80px;
						padding: 15px;
						box-sizing: border-box;
					}
				}
			}
		}
	}
</style>
