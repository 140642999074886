<template>
	<section class="signup">   
        <div class="signup_content"> 
            <div class="action"  v-if="isBack"  @click="$router.back()">
                <img :src="require('@/assets/image/hodgepodge/zuo.png')" alt="" />
            </div> 

            <div class="content"> {{title}} </div> 
		</div>
    </section>
</template>

<script>
export default { 
	name: 'signup',
	data() {
		return {  
            isBack:true,
		};
	},
    props: {
		title: {
			type: String,
			default: '默认标题'
		},
        isBack:{
            type:Boolean,
            default:true
        }
		 
	},
	computed: {
		
	}, 
	components: { 
		
	},
	watch: { 
		
	}, 
	created() {  

	},
	mounted() {
		
	},
	methods: { 
	}, 
};
</script>

<style lang="scss" scoped>
  .signup{
    width: 100%;
    height: 0.80rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;

    .signup_content{
        width: 100%; 
        display: flex;
        align-items: center;
        padding: 0 0.32rem;
        box-sizing: border-box;
        position: relative;

        .action{
            width:0.56rem;
            height: 0.56rem;
            position: absolute;
            left: 0.32rem;
            bottom: 0;
            top: 0;
            margin: auto;
            display: flex;

            img{
                width: 100%;
                height: 100%;
            }
        }

        .content{
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 0.36rem;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000; 
        }
    } 
  }
	
</style>