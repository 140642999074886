export default { 
    data() {
      return {
        //  比赛章程
        constitutionUrl:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-09/ea2953b22d68bba15c95dc5f6ebfb24d.pdf',
        //  app下载
        appUrl:'https://app.chineserd.hk',
        // 赛事质询
        whatUrl:'https://wa.me/85291496563',
        // h5banner
        h5BannerUrl:require('@/assets/image/hodgepodge/h5banner.png'),
        // pcbanner
        pcBannerUrl:require('@/assets/image/hodgepodge/pcbanner.png'),  
      };
    },
    computed: {},
    components: { 
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
        
    },
  }; 