<template>
  <section class="views">
    <headers :isBack="true" title="報名成功"></headers>
    <div class="views_content">
      <img
        :src="require('@/assets/image/hodgepodge/ko.png')"
        alt=""
        class="kong"
      />

      <div class="h1_title">報名成功</div>

      <div class="h2_title">你已報名成功，請留意電郵並詳閱比賽相關指引，謝謝！</div>

      <div class="anniu" @click="but">
        <span>點擊並回覆【App】獲取比賽APP</span>
      </div>
    </div>
  </section>
</template>
  
  <script>
import headers from "@/components/header/custom";
import publics from '@/mixin/public_mixin';
export default {
  name: "views",
  data() {
    return {};
  },
  computed: {},
  mixins: [publics],
  components: {
    headers,
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    but(){
      // this.$router.replace('/successful') 
      window.open(this.whatUrl,"_blank");
    }
  },
};
</script>
  
  <style lang="scss" scoped>
.views {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .views_content {
    width: 100%;
    height: calc(100vh - 0.8rem);
    overflow-x: hidden;
    background: #fafafa;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    align-content: center;

    .kong {
      width: 1.36rem;
      height: 1.36rem;
    }

    .h1_title {
      width: 100%;
      text-align: center;
      font-size: 0.32rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin-top: 0.24rem;
    }

    .h2_title {
      width: 100%;
      font-size: 0.28rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
      margin-top: 0.24rem;
    }

    .anniu {
      margin: auto;
      margin-top: 0.48rem;
      width: 5.10rem;
      height: 0.96rem;
      text-align: center; 
      font-size:0.28rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF; 
      display: flex;
      align-items: center;
      justify-content: center;
      background: url("~@/assets/image/hodgepodge/anniu.png") no-repeat;
      background-size: 100% 100%;

      img {
        width: 100%;
      }
    }
  }
}
</style>
  